export const MainConfig = {
    AppDetails: {
        app_name: "Tuning Taxi",
        app_description: "Tuning Taxi Booking App",
        app_identifier: "com.pa.tt",
        ios_app_version: "1",
        android_app_version: 1
    },
    FirebaseConfig: {
        apiKey: "AIzaSyBIamR6RXMokfPKpJbcOtbRPdZHt547PQM",
        authDomain: "tuning-taxi.firebaseapp.com",
        databaseURL: "https://tuning-taxi-default-rtdb.firebaseio.com",
        projectId: "tuning-taxi",
        storageBucket: "tuning-taxi.appspot.com",
        messagingSenderId: "918550165771",
        appId: "1:918550165771:web:145dc0f9c3a32dde6c8a0e",
        measurementId: "G-WT1JLZWETJ"
    },
    Google_Map_API_Key: {
        ios: "AIzaSyCvEIAg3KipikutfUXzQ-waVdHL0-Ymy7E",
        android: "AIzaSyCvEIAg3KipikutfUXzQ-waVdHL0-Ymy7E",
        web: "AIzaSyCvEIAg3KipikutfUXzQ-waVdHL0-Ymy7E"
    },
    facebookAppId: "2598386703715971",
    PurchaseDetails: {
        CodeCanyon_Purchase_Code: "905b2435-8e0f-4433-b25e-f0fd1bcc52d3",
        Buyer_Email_Address: "pba247@gmail.com"
    }
}